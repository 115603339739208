const Compressor = require('compressorjs');
const ExifReader = require('exifreader');

document.addEventListener('turbolinks:load', () => {
  const select_btn = document.querySelector('#select');
  const upload_btn = document.querySelector('#upload');
  const cancel_btn = document.querySelector('#cancel');
  const photo_input = document.getElementById("photo");
  const progress_and_result = document.getElementById("progress_and_result");
  const upload_main = document.getElementById("upload_main");
  const protocol = document.getElementById("protocol");
  const trip_selector = document.getElementById("photograph_trip_id");
  const town_selector = document.getElementById("photograph_town_id");

  var amount = 0;
  var sent = 0;
  var processed = 0;
  var saved = 0;
  var not_saved = 0;
  var not_processed = 0;
  var not_sent = 0;

  select_btn.addEventListener('click', function() {
    photo_input.click();
  });

  photo_input.addEventListener('change', function(event) {
    $('div.upload-preview').empty();
    select_btn.style.display = "none";
    ImagePreview(0);
  });

  function ImagePreview(i) {
    var item = photo_input.files.item(i)
    if (!item) {
      upload_btn.style.display = "block";
      cancel_btn.style.display = "block";
      return;
    }
    // var reader = new FileReader();
    // reader.onload = function(event) {
    //   $($.parseHTML('<img>')).attr('src', event.target.result).appendTo('div.upload-preview');
    //   ImagePreview(i+1)
    // }
    //
    // reader.readAsDataURL(photo_input.files[i]);
    filename = item.name
    para = document.createElement("p");
    para.innerHTML = filename;
    document.getElementById("preview_area").appendChild(para);
    ImagePreview(i+1);
  }

  cancel_btn.addEventListener('click', function() {
    location.reload();
  });

  upload_btn.addEventListener('click', function() {
    progress.style.display = "flex";
    upload_main.innerHTML = "";
    amount = photo_input.files.length;
    UploadForm(0);
  });

  const form = document.querySelector('#new_photograph');
  function UploadForm(l) {
    var formData = new FormData(form);
    var file = photo_input.files[l]
    const amount = photo_input.files.length
    if (!file) {
      progress.style.display = "none";
      document.getElementById("amount").innerHTML = amount;
      document.getElementById("sent").innerHTML = sent;
      document.getElementById("processed").innerHTML = saved+not_saved;
      document.getElementById("saved").innerHTML = saved;
      document.getElementById("not_saved").innerHTML = not_saved;
      document.getElementById("not_processed").innerHTML = not_processed;
      document.getElementById("not_sent").innerHTML = not_sent;
      protocol.style.display = "flex";
      if (trip_selector.value && saved > 0) {
        link = document.getElementById("trip");
        link.href= `/trips/${trip_selector.value}`;
        link.innerHTML = trip_selector.options[trip_selector.selectedIndex].text;
      }
      if (town_selector.value && saved > 0) {
        link = document.getElementById("town");
        link.href= `/towns/${town_selector.value}`;
        link.innerHTML = town_selector.options[town_selector.selectedIndex].text;
      }
      return;
    }

    const reader = new FileReader()
    reader.onload = function (event) {
      var tags = ExifReader.load(event.target.result);
      if (tags['DateTimeOriginal'] != undefined) {
        console.log(tags['DateTimeOriginal'].description);
        formData.append("exif_date", tags['DateTimeOriginal'].description);
      }
    };

    reader.readAsArrayBuffer(file);

    new Compressor(file, {
      quality: 0.8,
      maxWidth: 1920,
      maxHeight: 1920,
      success(result1) {
        formData.append("image", result1, result1.name);
        new Compressor(file, {
          quality: 0.8,
          maxWidth: 300,
          maxHeight: 300,
          success(result2) {
            formData.append("thumbnail", result2, result2.name);
            var request = new XMLHttpRequest();
            request.onreadystatechange = function() {
              if (this.readyState == 4) {
                document.getElementById("pb").style.width = `${(l+1)/amount*100}%`
                document.getElementById("progress_in_words").innerHTML = `${l+1} von ${amount}`
                if (this.status == 201) {
                  table_row = document.createElement("tr");
                  table_row.innerHTML = this.responseText;
                  document.getElementById("server_success").style.display = "block";
                  document.getElementById("tbody1").appendChild(table_row);
                  saved += 1;
                } else if (this.status == 200) {
                  table_row = document.createElement("tr");
                  table_row.innerHTML = this.responseText;
                  document.getElementById("server_fail").style.display = "block";
                  document.getElementById("tbody2").appendChild(table_row);
                  not_saved += 1;
                } else {
                  status_para = document.createElement("p");
                  status_para.innerHTML = `${result2.name} - Status ${this.status} (${this.statusText})`;
                  document.getElementById("unprocessed_errors").style.display = "block";
                  document.getElementById("unprocessed_errors").appendChild(status_para);
                  not_processed += 1;
                }
                if (l < amount) {
                  UploadForm(l+1);
                }
              }
            };
            request.open("POST", "/photographs");
            request.send(formData);
            sent += 1;
          },
          error(err) {
            if (l < amount) {
              error_para = document.createElement("p");
              error_para.innerHTML = `${file.name}(Thumbnail) - ${err.message}`;
              document.getElementById("browser_errors").style.display = "block";
              document.getElementById("browser_errors").appendChild(error_para);
              not_sent += 1;
              UploadForm(l+1);
            }
          },
        });
      },
      error(err) {
        if (l < amount) {
          error_para = document.createElement("p");
          error_para.innerHTML = `${file.name} - ${err.message}`;
          document.getElementById("browser_errors").style.display = "block";
          document.getElementById("browser_errors").appendChild(error_para);
          not_sent += 1;
          UploadForm(l+1);
        }
      },
    });
  };
})
