import "select2"

document.addEventListener('turbolinks:load', () => {
  $('.selectize').select2({
    placeholder: "-- bitte wählen --",
    allowClear: true,
  })
  window.$('#foretime_modal').on('show.bs.modal', function(event) {
    $('.selectize').select2({
      width: '100%',
      dropdownParent: $('#foretime_modal'),
      placeholder: "-- bitte wählen --",
      allowClear: true,
    })
  });
});
