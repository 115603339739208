$(document).on("turbolinks:load", function() {

  window.$('#area_form_modal').on('show.bs.modal', function(event) {
    name_input = $('#area_name');
    bg_color_input = $('#area_bg_color');
    color_input = $('#area_color');
    description_input = $('#area_description');
    area_label_preview = $('#area_label_preview');
    color_interchanger = $('.color-interchanger');

    createLabelPreview();
    showRemainingChar(description_input);

    function createLabelPreview() {
      area_label_preview.text(name_input.val());
      area_label_preview.css("background-color", bg_color_input.val());
      area_label_preview.css("color", color_input.val());
    }

    name_input.on('input', function() {
      createLabelPreview();
    });

    description_input.on('input', function() {
      showRemainingChar(description_input);
    });

    bg_color_input.on('input', function() {
      createLabelPreview();
    });

    color_input.on('input', function() {
      createLabelPreview();
    });

    color_interchanger.on('click', function() {
      interchangeColors(bg_color_input.val(), color_input.val());
    });

    $('#new_random_color').on('click', function() {
      newRandomColor();
    });

    $('#back_to_white').on('click', function() {
      backToWhite();
    });

    function interchangeColors(bg_color, color) {
      bg_color_input.val(color);
      color_input.val(bg_color);
      createLabelPreview();
    }

    window.newRandomColor = function() {
      bg_color_input.val(`#${Math.floor(Math.random()*16777215).toString(16)}`);
      backToWhite();
    }

    function backToWhite() {
      color_input.val(`#FFFFFF`);
      createLabelPreview();
    }

    function showRemainingChar(input_element) {
      let current_length = input_element.val().length;
      let max_length = input_element.attr("maxlength");
      input_element.siblings('span').children('i').text(current_length + "/" + max_length);
    }
  });
});
