const Compressor = require('compressorjs');

$(document).on("turbolinks:load", function() {
  var logo_select_btn;
  var logo_upload_btn;
  var logo_cancel_btn;
  var logo_input;
  var logo_preview_wrapper;
  var logo_form;
  var logo_preview_and_buttons;

  function initializeElements() {
    logo_select_btn = $('#logo_select');
    logo_upload_btn = $('#logo_upload');
    logo_cancel_btn = $('#logo_cancel');
    logo_input = $('#logo');
    logo_preview_and_buttons = $('#logo_preview_and_buttons');
    logo_preview_wrapper = $('#logo_preview_wrapper');
    logo_form = document.querySelector('#new_brand_logo');
    logo_errors = $('#logo_errors');
  }

  function addListenerToSelectBtn() {
    logo_select_btn.on('click', function() {
      logo_input.click();
    });
  }

  function addListenerToInputField() {
    logo_input.on('change', function(event) {
      if (event.target.files[0]) {
        logo_preview_wrapper.empty();
        logo_errors.empty();
        logo_preview = jQuery('<img/>', {"class": 'contain', "id": "logo_preview"});
        logo_preview.attr('src', URL.createObjectURL(event.target.files[0]));
        logo_preview.appendTo(logo_preview_wrapper);
        logo_select_btn.hide();
        logo_preview_and_buttons.show();
        logo_preview.onload = function () {
          URL.revokeObjectURL(logo_preview.attr('src'));
        }
      }
    });
  }

  function addListenerToCancelBtn() {
      logo_cancel_btn.on('click', function() {
      logo_input.val('');
      logo_select_btn.show();
      logo_preview_and_buttons.hide();
      logo_errors.empty();
    });
  }

  function addListenerToUploadBtn() {
    logo_upload_btn.on('click', function() {
      logo = logo_input.prop('files')[0];
      var formData = new FormData(logo_form);
      new Compressor(logo, {
        quality: 0.8,
        maxWidth: 300,
        maxHeight: 300,
        success(result) {
          formData.append("logo", result, result.name);
          var request = new XMLHttpRequest();
          request.onreadystatechange = function() {
          if (this.readyState == 4)
            if (this.status == 422) {
              alert(`ERROR - ${this.responseText}`);
            } else if (this.status == 200) {
              eval(this.responseText);
            }
          }
          request.open("POST", logo_form.action);
          request.send(formData);
        },
        error(err) {
          alert(`ERROR - ${err.message}`);
        }
      });
    });
  }

  function initializeAndAddEventListener() {
    initializeElements();
    addListenerToSelectBtn();
    addListenerToInputField();
    addListenerToCancelBtn();
    addListenerToUploadBtn();
  }

  window.$('#model_train_manufacturer_form_modal').on('show.bs.modal', function(event) {
    initializeAndAddEventListener();
  });
});
