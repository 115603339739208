import "selectize";
$(document).on("turbolinks:load", function() {
  $('.selectize-control').remove();
  window.$('[data-toggle="tooltip"]').tooltip()

  var selectizeCallback = null;

  window.$("#new_area").children("[type='submit']").on("click", function(e) {
    e.preventDefault();
    $(this).prop("disabled",true);
    $.ajax({
      method: "POST",
      url: $(this).parent().attr("action") + ".json",
      data: $("#new_area").serialize(),
      statusCode: {
        201: function(response) {
          selectizeCallback(response);
          selectizeCallback = null;
          window.$('#area_form_modal').modal('toggle');
        },
        422: function(response) {
          displayErrorMessages($("#new_area"), response);
        }
      },
    })
  })

  window.$("#new_scale").children("[type='submit']").on("click", function(e) {
    e.preventDefault();
    $(this).prop("disabled",true);
    $.ajax({
      method: "POST",
      url: $(this).parent().attr("action") + ".json",
      data: $("#new_scale").serialize(),
      statusCode: {
        201: function(response) {
          selectizeCallback(response);
          selectizeCallback = null;
          window.$('#scale_form_modal').modal('toggle');
        },
        422: function(response) {
          displayErrorMessages($("#new_scale"), response);
        }
      },
    })
  })



  window.$("#new_model_train_manufacturer").children("[type='submit']").on("click", function(e) {
    e.preventDefault();
    $(this).prop("disabled",true);
    $.ajax({
      method: "POST",
      url: $(this).parent().attr("action") + ".json",
      data: $("#new_model_train_manufacturer").serialize(),
      statusCode: {
        201: function(response) {
          selectizeCallback(response);
          selectizeCallback = null;
          window.$('#model_train_manufacturer_form_modal').modal('toggle');
        },
        422: function(response) {
          displayErrorMessages($("#new_model_train_manufacturer"), response);
        }
      },
    })
  })

  window.$('#area_form_modal').on("hide.bs.modal", function(e) {
    if (selectizeCallback != null) {
      selectizeCallback();
      selectizeCallback = null;
    }
  });

  window.$('#scale_form_modal').on("hide.bs.modal", function(e) {
    if (selectizeCallback != null) {
      selectizeCallback();
      selectizeCallback = null;
    }
  });

  window.$('#model_train_manufacturer_form_modal').on("hide.bs.modal", function(e) {
    if (selectizeCallback != null) {
      selectizeCallback();
      selectizeCallback = null;
    }
  });


  $('.model-area-selectize').selectize({
    closeAfterSelect: true,
    placeholder: gon.global.locales.placeholder,
    valueField: 'id',
    labelField: 'name',
    searchField: ['name', 'description'],
    options: gon.areas,
    items: gon.selected_areas,
    create: function(input, callback) {
      selectizeCallback = callback;

      window.$('#area_form_modal').modal();

      removeErrorMessages($('#new_area'));
      name_input.val(input);
      $('#area_description').val("").focus();
      newRandomColor();
    },
    render: {
        item: function(item, escape) {
            return `<span class="area-label" style="background-color:${item.bg_color};color:${item.color};"> ${escape(item.name)}</span>`;
        },
        option: function(item, escape) {
            return `<div class="area-in-dropdown"><span class="area-label" style="background-color:${item.bg_color};color:${item.color};"> ${escape(item.name)}</span><p class="area-description">${escape(item.description)}</p></div>`;
        },
        option_create: function(data) {
          var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
          if (!format.test(data.input)) {
            var option_create_html = gon.global.locales.option_create.replace(`xyz`,`<b>${data.input}</b>`);
            return `<span class="create">${option_create_html}</span>`;
          } else {
            return `<span class="create">${gon.global.locales.not_allowed}</span>`;
          }

        }
    }
  });

  $('.model-scale-selectize').selectize({
    closeAfterSelect: true,
    placeholder: gon.global.locales.placeholder,
    valueField: 'id',
    labelField: 'name',
    searchField: ['name', 'gauge_with_mm', 'ratio_with_one'],
    options: gon.scales,
    items: [gon.selected_scale],
    create: function(input, callback) {
      selectizeCallback = callback;
      window.$('#scale_form_modal').modal();
      removeErrorMessages($('#new_scale'));
      $('#scale_name').val(input);
      $('#scale_gauge').val("").focus();
    },
    render: {
        item: function(item, escape) {
            return `<span class="scale-label bg-dark rounded text-white px-1"> ${escape(item.name)}</span>`;
        },
        option: function(item, escape) {
            return `<div class="area-in-dropdown"><span class="scale-label bg-dark rounded text-white px-1"> ${escape(item.name)}</span><p class="area-description">${escape(item.ratio_with_one)} | ${escape(item.gauge_with_mm)}</p></div>`;
        },
        option_create: function(data) {
          var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
          if (!format.test(data.input)) {
            var option_create_html = gon.global.locales.option_create.replace(`xyz`,`<b>${data.input}</b>`);
            return `<span class="create">${option_create_html}</span>`;
          } else {
            return `<span class="create">${gon.global.locales.not_allowed}</span>`;
          }

        }
    }
  });

  $('.model-model-train-manufacturer-selectize').selectize({
    closeAfterSelect: true,
    placeholder: gon.global.locales.placeholder,
    valueField: 'id',
    labelField: 'name',
    searchField: ['name'],
    options: gon.model_train_manufacturers,
    items: [gon.selected_model_train_manufacturer],
    create: function(input, callback) {
      selectizeCallback = callback;

      window.$('#model_train_manufacturer_form_modal').modal();

      removeErrorMessages($('#new_model_train_manufacturer'));
      $("#model_train_manufacturer_name").val(input);
      $('#model_train_manufacturer_description').val("").focus();
    },
    render: {
      item: function(item, escape) {
          return `<span class="logo-as-item-wrap mr-1"><img src="${item.logo_url}" class="contain"/></span>`;
      },
      option: function(item, escape) {
          return `<div class="area-in-dropdown"><img src="${item.logo_url}" style="height:22px"/><p class="area-description">${escape(item.name)}</p></div>`;
      },
      option_create: function(data) {
        var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
        if (!format.test(data.input)) {
          var option_create_html = gon.global.locales.option_create.replace(`xyz`,`<b>${data.input}</b>`);
          return `<span class="create">${option_create_html}</span>`;
        } else {
          return `<span class="create">${gon.global.locales.not_allowed}</span>`;
        }
      }
    }
  });

  $('.generic-filter-selectize').selectize({
    closeAfterSelect: true,
    placeholder: gon.global.locales.placeholder
  });

  $('.model-area-filter-selectize').selectize({
    closeAfterSelect: true,
    placeholder: gon.global.locales.placeholder,
    valueField: 'id',
    labelField: 'name',
    searchField: ['name', 'description'],
    options: gon.areas,
    items: gon.selected_areas,
    create: false,
    render: {
        item: function(item, escape) {
            return `<span class="area-label" style="background-color:${item.bg_color};color:${item.color};"> ${escape(item.name)}</span>`;
        },
        option: function(item, escape) {
            return `<div class="area-in-dropdown"><span class="area-label" style="background-color:${item.bg_color};color:${item.color};"> ${escape(item.name)}</span><p class="area-description">${escape(item.description)}</p></div>`;
        }
    }
  });

  $('.m-t-manufacturer-filter-selectize').selectize({
    closeAfterSelect: true,
    placeholder: gon.global.locales.placeholder,
    valueField: 'id',
    labelField: 'name',
    searchField: ['name'],
    options: gon.model_train_manufacturers,
    items: gon.selected_model_train_manufacturers,
    create: false,
    render: {
      item: function(item, escape) {
          return `<span class="logo-as-item-wrap mr-1"><img src="${item.logo_url}" class="contain"/></span>`;
      },
      option: function(item, escape) {
          return `<div class="area-in-dropdown"><img src="${item.logo_url}" style="height:22px"/><p class="area-description">${escape(item.name)}</p></div>`;
      }
    }
  });

  $('.scale-filter-selectize').selectize({
    closeAfterSelect: true,
    placeholder: gon.global.locales.placeholder,
    valueField: 'id',
    labelField: 'name',
    searchField: ['name'],
    options: gon.scales,
    items: gon.selected_scales,
    create: false,
    render: {
        item: function(item, escape) {
            return `<span class="area-label bg-dark text-white"> ${escape(item.name)}</span>`;
        },
        option: function(item, escape) {
            return `<div class="area-in-dropdown"><span class="area-label bg-dark text-white"> ${escape(item.name)}</span><p class="area-description">${escape(item.ratio_with_one)} - ${escape(item.gauge_with_mm)}</p></div>`;
        }
    }
  });

  $('.vehicle-association-selectize').selectize({
    closeAfterSelect: true,
    placeholder: gon.global.locales.placeholder,
    valueField: 'id',
    labelField: 'name',
    searchField: ['name', 'nicknames'],
    options: gon.vehicles,
    items: [gon.selected_vehicle],
    create: false,
    render: {
        item: function(item, escape) {
            return `<span> ${escape(item.name)}</span>`;
        },
        option: function(item, escape) {
            return `<div class="area-in-dropdown"><span> ${escape(item.name)}</span><p class="area-description">${item.nicknames? escape(item.nicknames) : ""}</p></div>`;
        }
    }
  });

  $('.prototype-association-selectize').selectize({
    closeAfterSelect: true,
    placeholder: gon.global.locales.placeholder,
    valueField: 'id',
    labelField: 'designation',
    searchField: ['designation', 'nicknames'],
    options: gon.prototypes,
    items: [gon.selected_prototype],
    create: false,
    render: {
        item: function(item, escape) {
            return `<span> ${escape(item.designation)}</span>`;
        },
        option: function(item, escape) {
            return `<div class="area-in-dropdown border m-1"><b> ${escape(item.designation)}</b><p class="area-description">${item.nicknames? escape(item.nicknames) : ""}</p></div>`;
        }
    }
  });

  $('.model-vehicle-selectize').selectize({
    closeAfterSelect: true,
    placeholder: gon.global.locales.placeholder,
    valueField: 'id',
    labelField: 'name_1',
    searchField: ['name_1', 'name_2'],
    options: gon.model_vehicles,
    items: gon.selected_model_vehicles,
    create: false,
    render: {
        option: function(item, escape) {
            return `<div class="area-in-dropdown border m-1"><b> ${escape(item.name_1)}</b><p class="area-description">${item.name_2? escape(item.name_2) : ""} (${item.owner_name? escape(item.owner_name) : ""}${item.m_t_manufacturer_name? ", " + escape(item.m_t_manufacturer_name) : ""})</p></div>`;
        }
    }
  });

  $.ajaxSetup({
    headers: {
      'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
    }
  });

  function displayErrorMessages(html_form, response) {
    removeErrorMessages(html_form);
    for (var attribute in response.responseJSON) {
      let input_field = html_form.find(`[name*='${attribute}']`);
      let error_div_id = `${html_form.attr('id')}_${attribute}_errors`;
      input_field.addClass("is-invalid");
      input_field.parent().append(`<div id="${error_div_id}" class="input-error"></div>`);
      for (var error_message of response.responseJSON[attribute]) {
        $(`#${error_div_id}`).append(`<span class="small">&#9888; ${error_message}</span>`);
      }
    }
  }

  function removeErrorMessages(html_form) {
    html_form.children("[type='submit']").prop("disabled", false);
    let invalid_fields = html_form.find(".is-invalid");
    invalid_fields.nextAll('div').remove();
    invalid_fields.removeClass("is-invalid");
  }
});
